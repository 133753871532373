import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import '@mdi/font/css/materialdesignicons.css'; 

Vue.use(Vuetify);

export default new Vuetify({ 
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        themes: {
            light: {
                primary: '#f0a500', // '#ED4036',
                secondary: '#F27405',  
                palet03: '#F2F2F2',
                accent: '#b38e5d',
                error: '#f44336',
                warning: '#FF9800',
                info: '#2196F3',
                success: '#4caf50',
                grey200: '#b5b5b5',
                grey400: '#666666',
                grey600: '#3c3c3c',
                black600: '#3a3a3a',
                activeColor: '#f9e5eb',

                proceso: '#ffa43a',
                cerrada: '#525558',
                autorizada: '#759eff',
                pendientepago: '#f9e5eb',
                pagada: '#4caf50',
                vigente: '#00c6ab',
                preliminar: '#ffc723',
                confirmada: '#416864',
                liberada: '#223026',
                aprobada: '#3f8880',
                preautorizacion: '#9dc09d',
                rechazada: '#d6a735',
                editar: '#FF8000',

                vigente: '#20603d',
                preparar: '#ffc40c',
                proximaexp: '#F56C07',
                expirada: '#F5050D',

                PROCESO: '#2196F3',
                EDICION: '#759eff',
                PREAUTORIZADO:'#F77D18',
                RECHAZADO:'#8C1616',
                CANCELADA: '#F5050D',
                FINALIZADA: '#11682A',
                PENDIENTEDOC: '#ffa43a',

                PAGADA:'#4caf50',
                SININGRESAR:'#f44336',
                PENDIENTEPAGO:'#ffc40c',
            },
        },
    },
});
