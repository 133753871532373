import MessagningService from '../services/messaging.services'
const state = {
    fcmToken: null,
    messages: [],
    messagesList: [],
    messagesToolbar: [],
    success: false,
    // new_messages: [],
    // all_messages: [],
    // messages_db: [],
    // message_sender: false
}
const getters = {
    getToken: (state) => state.fcmToken,
    // getAllMessages: (state) => state.all_messages,
    getMessagesFMC: (state) => state.messages,
    getMessages: (state) => state.messagesList,
    getMessagesTB: (state) => state.messagesToolbar,
    // getStateSender: (state) => state.message_sender
}
const actions = {
    set_token({ commit }, token) {
        commit('SET_TOKEN', token);
    },
    add_message({ commit }, msg) {
        commit('SET_MESSAGE', msg);
    },
    subscribe_to_group({ commit }) {
        const userData = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        const fcmToken = localStorage.getItem('midgrab.fcm.token');
        if (userData && userData.data.rol_code) {
            let payload = {
                token: fcmToken,
                topic: userData.cia ? userData.cia.number_code : userData.data.rol_code
            }
            return MessagningService.post_service(payload, process.env.VUE_APP_NOTIFY_SUBSCRIBE_USER).then(
                () => {
                    commit('SET_TOPIC', 'subscribe');
                    return Promise.resolve(200);
                },
                error => {
                    console.log(error)
                    commit('SET_TOPIC', false);
                    return Promise.reject(error);
                }
            ).catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
        }
    },
    unsubscribe_to_group({ commit }) {
        const userData = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        const fcmToken = localStorage.getItem('midgrab.fcm.token');
        if (userData && userData.data.rol_code) {
            let payload = {
                token: fcmToken,
                topic: userData.cia ? userData.cia.number_code : userData.data.rol_code
                //topic: userData && userData.data.rol_code ? userData.data.rol_code : null
            }
            return MessagningService.post_service(payload, process.env.VUE_APP_NOTIFY_UNSUBSCRIBE_USER).then(
                () => {
                    commit('SET_TOPIC', null);
                    return Promise.resolve(200);
                },
                error => {
                    console.log(error)
                    commit('SET_TOPIC', null);
                    return Promise.reject(error);
                }
            ).catch((error) => {
                console.log(error);
                return Promise.reject(error);
            });
        }
    },
    send_message({ commit }, data) {
        return MessagningService.post(data, process.env.VUE_APP_NOTIFY_GROUP).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ status: response.status, data: response.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
        //commit('SET_MESSAGE', msg);
    },
    get_messages({ commit }) {
        let user = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (user) {
            return MessagningService.get_messageUI(`${process.env.VUE_APP_API_ENTITY_NOTIFY}/unreaded`)
                .then(response => {
                    commit('SET_LIST_MESSAGE_TOOLBAR', response.data.data);
                }).catch((error) => {
                    console.log(error);
                    return Promise.reject(error);
                });
        }
    },
    // get_my_message({ commit }) {
    //     let user = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    //     if (user) {
    //         return MessagningService.get_my_message(`${process.env.VUE_APP_API_ENTITY_NOTIFY}/${user.data.uuid}`).then(
    //             response => {
    //                 commit('SET_LIST_MESSAGE', response.data.data);
    //                 return Promise.resolve(response.status);
    //             },
    //             error => {
    //                 console.log(error)
    //                 commit('SET_LIST_MESSAGE', null);
    //                 return Promise.reject(error);
    //             }
    //         ).catch((error) => {
    //             console.log(error);
    //             return Promise.reject(error);
    //         });
    //     }
    // },
    // get_my_all_message({ commit }) {
    //     return MessagningService.get_my_message(process.env.VUE_APP_API_ENTITY_NOTIFY).then(
    //         response => {
    //             commit('SET_LIST_ALL_MESSAGE', response.data.data);
    //             return Promise.resolve(response.status);
    //         },
    //         error => {
    //             return Promise.reject(error);
    //         }
    //     ).catch((error) => {
    //         return Promise.reject(error);
    //     });
    // },
    set_isReadMessage({ commit }, data) {
        return MessagningService.update_entity(data.item, data.entity).then(
            response => {
                commit('SET_READED_MESSAGE', data.item);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_READED_MESSAGE', data.item);
                return Promise.reject(error);
            }
        );
    },
    set_isReadMessageT({ commit }, data) {
        console.log('data', data)
        return MessagningService.update_entity(data.item, data.entity).then(
            response => {
                commit('SET_READED_MESSAGET', data.item);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_READED_MESSAGET', data.item);
                return Promise.reject(error);
            }
        );
    },
    send_messageGroup({ commit }, data) {
        return MessagningService.post_service(data, process.env.VUE_APP_NOTIFY_GROUP).then(
            () => {
                commit('SEND_MESSAGE', true);
                return Promise.resolve(200);
            },
            error => {
                console.log(error)
                commit('SEND_MESSAGE', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
}
const mutations = {
    SET_LIST_MESSAGE_TOOLBAR(state, rs) {
        
        state.messagesToolbar = rs;
    },
    SET_SUCCESS(state, sc) {
        state.success = sc;
    },
    SET_TOKEN(state, tk) {
        state.fcmToken = tk;
        localStorage.setItem('midgrab.fcm.token', tk);
    },
    SET_MESSAGE(state, msg) {
        state.messages.push(msg);
    },
    SET_LIST_ALL_MESSAGE(state, msgs) {
        if (msgs && msgs.length >= 1) {
            msgs.forEach(msg => {
                state.messages_db.push(msg);
            });
        }
    },
    SET_LIST_MESSAGE(state, msgs) {
        if (msgs && msgs.length >= 1) {
            msgs.forEach(msg => {
                state.new_messages.push(msg);
            });
        }
    },
    SET_READED_MESSAGE(state, msg) {
        if (msg && msg.is_readed && state.messages) {

            // let idx = state.new_messages.indexOf(msg);
            // if (idx != -1) {
            //     state.new_messages.splice(idx, 1);
            // }
            let idx = state.messagesToolbar.unreaded.indexOf(msg);
            if (idx != -1) {
                state.messagesToolbar.unreaded.splice(idx, 1);
            }
        }
    },
    SET_READED_MESSAGET(state, msg){
        msg.notifications.map((element)=>{
            if (element && element.is_readed && state.messages) {

                // let idx = state.new_messages.indexOf(msg);
                // if (idx != -1) {
                //     state.new_messages.splice(idx, 1);
                // }
                let idx = state.messagesToolbar.unreaded.indexOf(element);
                if (idx != -1) {
                    state.messagesToolbar.unreaded.splice(idx, 1);
                }
            }
        })
       
    },
    SET_TOPIC(state, data) {
        state.topic = data;
    },
    SEND_MESSAGE(state, std) {
        state.message_sender = std;
    },
}
export const messaging = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
