import Repository from '../services/repository.services'
import UIService from '../services/uninterceptor.services'
const PREFIX = 'system/';

const state = {
    departamentos: [],
    noticeDepartamentos:[],
    comments: [],
    noticias:[],
    notice:[],
    locations: [],
    noticiasItem:{},
    success: false
}

const getters = {
    getSelectDeptos: (state) => state.departamentos,
    getSelectNoticeDeptos: (state) => state.noticeDepartamentos,
    getComments: (state) => state.comments,
    getList: (state) => state.noticias,
    getDatos: (state) => state.noticiasItem,
    getListMe: (state) => state.notice,
    getLocations: (state) => state.locations,
}
const actions = {
    getUIList({ commit }, entity) {
        return UIService.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    saveComments({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({response:response.status, datos:response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpdate({ commit }, data) {
        return Repository.update(data.item,`${PREFIX}${data.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        return Repository.delete(data.item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "select_deptos":
                    state.departamentos = res.response.data;
                    break;
                case "commentarios":
                    state.comments = res.response.data;
                    break; 
                case "noticias":
                    state.noticias = res.response.data;
                    break;
                case "noticias_me":
                    state.notice = res.response.data;
                    break;
                case "noticias_deptos":
                    state.noticeDepartamentos = res.response.data;
                    break;
            }
        }
    },
    SET_ITEM(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "noticias":
                    state.noticiasItem = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const system = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
