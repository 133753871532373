<template>
  <transition name="fade">
    <div class="toasts-wrapper" v-if="toasts.length > 0">
      <Toast v-for="(t, index) of toasts" :key="index" :toast="t"> </Toast>
    </div>
  </transition>
</template>

<script>
import Toast from "./Toast.vue";
export default {
  name: "Toasts",
  components: { Toast },
  computed: {
    toasts() {
       return this.$store.getters["toasts/getToasts"];
    },
  },
};
</script>

<style>
.toasts-wrapper {
  width: 600px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 1rem;
  top: 1rem;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .9s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
</style>