import axios from 'axios';
const API_URL = process.env.VUE_APP_SERVICES_HOST;
const PREFIX = 'companies/';

class ClientService{
    get(entity) {        
        return axios.get(API_URL + PREFIX +  entity);
    } 
    post(payload, entity) {        
        return axios.post(`${API_URL}${PREFIX}${entity}`, payload);
    }
    update(payload, entity){
        return axios.put(API_URL + PREFIX + entity, payload);
    }
}
export default new ClientService();