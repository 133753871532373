const state = {
    toasts: []
}

const getters = {
    getToasts: (state) => state.toasts,
}

const actions = {
    addToast({ commit }, toast) {
        console.log("add toast")
        commit('ADD_TOAST', toast);
    },
    notify_cm({ commit }, cm) {
        let toast = {
            title: cm.title,
            type: cm.type,
            message: cm.msg,
        }
        commit('ADD_TOAST', toast);
    },
    notify_mailsend({ commit }) {        
        let toast = {
            title: "Correo enviado",
            type: "success",
            message: "El email fué procesado y enviado con éxito.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_user_created({ commit }) {
        let toast = {
            title: "Acción Ejecutada",
            type: "success",
            message: "El usuario fué creado con éxito.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_saved({ commit }) {
        console.log("notify_saved")
        let toast = {
            title: "Acción Ejecutada",
            type: "success",
            message: "El registro fué creado con éxito.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_upated({ commit }) {
        let toast = {
            title: "Acción Ejecutada",
            type: "success",
            message: "El registro fué actualizado con éxito.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_get({ commit }) {
        let toast = {
            title: "La acción no pudo ser ejecutada",
            type: "error",
            message: "La consulta no se realizó de forma correcta, por favor intente de nuevo o reportelo con el administrador del sistema.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_saved({ commit }) {
        let toast = {
            title: "La acción no pudo ser ejecutada",
            type: "error",
            message: "El registro no pudo ser creado, por favor intente nuevamente o reportelo al administrador de sistemas.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_updated({ commit }) {
        let toast = {
            title: "La acción no pudo ser ejecutada",
            type: "error",
            message: "El registro no pudo ser acualizado, por favor intente nuevamente o reportelo al administrador de sistemas.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_message({ commit }, msg) {
        let toast = {
            title: "Error",
            type: "error",
            message: msg,
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_form({ commit }) {
        let toast = {
            title: "Campos incompletos",
            type: "warning",
            message: "Faltan campos con información o la informacion no es correcta. Favor de verificar los campos marcados de rojo.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_deleted({ commit }) {
        let toast = {
            title: "Acción borrar ejecutada",
            type: "success",
            message: "El registro fué eliminado con éxito.",
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_action({ commit }) {
        commit('ADD_TOAST', "ERROR_ACTION");
        let toast = {
            title: "Acción no ejecutada",
            type: "warning",
            message: "La acción solicitada no pudo completarse correctamente, por favor intente de nuevo o reportelo con el administrador del sistema."
        }
        commit('ADD_TOAST', toast);
    },
    notify_custom({ commit }, msg) {
        let toast = {
            title: "Atención",
            type: "warning",
            message: msg,
        }
        commit('ADD_TOAST', toast);
    },

    notify_unauthorized({ commit }) {
        let toast = {
            title: "Acceso no autorizado",
            type: "error",
            message: "La acción solicitada no pudo completarse debido a que no esta autorizado, consulte con el administrador del sistema.",
            btn_navigate: {
                label: "Entendido",
                link: "/"
            }
        }
        commit('ADD_TOAST', toast);
    },

    /*NOTIFICACIONES DE COTIZACIONES*/
    notify_saved_preliminary({ commit }) {
        let toast = {
            title: "Nueva cotización",
            type: "success",
            message: "La cotización se generó satisfactoriamente.",
            btn_navigate: {
                label: "Aceptar",
                link: "/cotizaciones"
            }
        }
        commit('ADD_TOAST', toast);
    },

    notify_confirm_quote({ commit }) {
        let toast = {
            title: "Cotización Timbrada",
            type: "success",
            message: "La cotización ha sido timbrada.",
            btn_navigate: {
                label: "Aceptar",
                link: "/cotizaciones"
            }
        }
        commit('ADD_TOAST', toast);
    },

    notify_approved_quote({ commit }) {
        let toast = {
            title: "Cotización Aprobada",
            type: "success",
            message: "La cotización ha sido aprobada por el cliente.",
            btn_navigate: {
                label: "Aceptar",
                link: "/cotizaciones"
            }
        }
        commit('ADD_TOAST', toast);
    },

    notify_authorized_quote({ commit }) {
        let toast = {
            title: "Cotización Autorizada",
            type: "success",
            message: "La cotización ha sido autorizada por administración.",
            btn_navigate: {
                label: "Aceptar",
                link: "/cotizaciones"
            }
        }
        commit('ADD_TOAST', toast);
    },

    notify_messagesFMC({ commit }, m) {
        let toast = {
            title: m.notification.title,
            type: "success",
            message: "Ha recibido nuevos mensajes, en su bandeja: " + m.notification.body,
            btn_navigate: {
                label: "Aceptar",
                link: "/notificaciones"
            }
        }
        commit('ADD_TOAST', toast);
    },

    notify_custom_navigate({ commit }, data) {
        let toast = {
            title: data.title,
            type: "success",
            message: data.msg,
            btn_navigate: {
                label: data.btnLabel,
                link: data.btnLink
            }
        }
        commit('ADD_TOAST', toast);
    },


    clearToast({ commit }, title) {
        commit('CLEAR_TOAST', title);
    },

    /*NOTIFICACIONES DE SESION*/
    notify_authenticated({ commit }) {
        let toast = {
            title: "Autenticacion exitosa",
            type: "success",
            message: "Se han validado sus credenciales con éxito."
        }
        commit('ADD_TOAST', toast);
    },
    notify_need_authenticate({ commit }) {
        let toast = {
            title: "Sesión expirada",
            type: "suwarningccess",
            message: "Su sesión y token han expirado, se requiere nuevamente validar sus credenciales.",
            btn_navigate: {
                label: "Iniciar sesión",
                link: "/login"
            }
        }
        commit('ADD_TOAST', toast);
    },
    notify_error_location({ commit }, e) {
        let toast = {
            title: `${e.message.toUpperCase()}`,
            type: "error",
            message: `Debera permitir accesar a su ubicacion para poder ingresar al sistema. Error: ${e.message}`,
        }
        commit('ADD_TOAST', toast);
    },
}

const mutations = {
    ADD_TOAST(state, toast) {
        state.toasts.push(toast);
    },
    CLEAR_TOAST(state, title) {
        const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
        state.toasts.splice(index, 1); // remove toast from array
    },
}

export const toasts = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

