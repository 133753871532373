import Vue from 'vue'


export default function setup() {
    console.log("modules")
    const user_data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    const modules = user_data && user_data.data.modules ? user_data.data.modules : [];

    Vue.prototype.$getModules = function () {
        return modules;
    }
}