import axios from 'axios';
import store from '../store';


const API_URL = process.env.VUE_APP_SERVICES_HOST;
const PREFIX_AUTH = 'auth/';

const unintercepted_axiosInstance = axios.create();

unintercepted_axiosInstance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    config.headers.Requesterby = "anonymous";

    store.dispatch("configurations/open_loading");
    if (token && token.acstapp) {
        config.headers.Authorization = `Bearer ${token.acstapp}`;
        config.headers.Requesterby = token.data && token.data.name ? `${token.data.name} - ${token.data.email}` :  "anonymous";
      }
    

    return config

}, function (err) {
    return Promise.reject(err);
});

unintercepted_axiosInstance.interceptors.response.use(
    response => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.resolve(response);
    },
    error => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.reject(error.response);
    });

class AuthService {

    async login_form(user) {
        return unintercepted_axiosInstance.post(API_URL + PREFIX_AUTH + 'login', user);
    }

    me() {
        return axios.post(API_URL + PREFIX_AUTH + 'me');
    }

    register_form(user) {
        user.password_confirmation = user.password;
        return unintercepted_axiosInstance.post(API_URL + PREFIX_AUTH + 'register', user);
    }

    validate_account(user) {

        return unintercepted_axiosInstance.post(API_URL + PREFIX_AUTH + 'activate_account', user);
    }

    forgotPassword(user) {

        return unintercepted_axiosInstance.post(API_URL + PREFIX_AUTH + 'restore_psw', user);
    }
    setPassword(user) {

        return unintercepted_axiosInstance.post(API_URL + PREFIX_AUTH + 'restore_psw_account', user);
    }
    update_data(payload){
        return axios.put(API_URL + PREFIX_AUTH + 'profile', payload);
    }

    update_password(payload){
        return axios.put(API_URL + PREFIX_AUTH + 'me', payload);
    }
}

export default new AuthService();