import ReportService from '../services/reports.services'

const data_arr = JSON.parse(localStorage.getItem('temp_data_arr'));


const state = {
    //ds_data_element: data_arr ? data_arr : { headers: [], elements: [] },
    production_line: [],
    headers_table: [],
    ds_table: [],
    success: false,
    order_services: [],
    clients: [],
    client_base: [],
    reports: [],
    equipment: [],
    categories: [],
    accessories: [],
    methods_end: [],
    reference_norms: [],
    criterios: [],
    procedures: [],
    tools_inspection: [],
    surface_states: [],
    coating_types: [],
    material_types: [],
    lighting_type: [],
    inspector: [],
    supervisor: [],
    images_report: [],
    pipetemplate_tbl: [],
    catalog: {},
    item_equipment: {},
    item_reference_norm: {},
    item_certificate: {},
    item_report_master: {},
    item_order_service: {},
}

const getters = {
    getClients: (state) => state.clients,
    getProductionLine: (state) => state.production_line,
    getClientBase: (state) => state.client_base,
    getReports: (state) => state.reports,
    getEquipment: (state) => state.equipment,
    getCategories: (state) => state.categories,
    getAccessories: (state) => state.accessories,
    getMethodsEND: (state) => state.methods_end,
    getReferenceNorms: (state) => state.reference_norms,
    getCriterios: (state) => state.criterios,
    getProcedures: (state) => state.procedures,
    getToolsInspection: (state) => state.tools_inspection,
    getSurfaceState: (state) => state.surface_states,
    getCoatingTypes: (state) => state.coating_types,
    getMaterialTypes: (state) => state.material_types,
    getLightingTypes: (state) => state.lighting_type,
    getInspector: (state) => state.inspector,
    getSupervisor: (state) => state.supervisor,
    getCatalog: (state) => state.catalog,
    getPipelineTemplateTbl: (state)=> state.pipetemplate_tbl,

    getItemReportMaster: (state) => state.item_report_master,
    getItemEquipment: (state) => state.item_equipment,
    getItemReferenceNorm: (state) => state.item_reference_norm,
    getImagesReport: (state) => state.images_report,
    getItemCertificate: (state) => state.item_certificate,
    //getElementDS: (state) => state.ds_data_element,
    getHeadersTable: (state) => state.headers_table,
    getDSTable: (state) => state.ds_table,

    getOrderServicesDS: (state) => state.order_services,
    getItemOrderServices: (state) => state.item_order_service,
}

const actions = {
    update_headers_table({ commit }, data) {
        commit('SET_HEADERS', data);
    },
    clear_headers({ commit }) {
        commit('CLEAR_HEADERS');
    },
    update_ds_table({ commit }, data) {
        commit('SET_DS_TABLE', data);
    },
    // add_custom_headers({ commit }, data) {
    //     commit('SET_HEADERS', data);
    // },
    // remove_item_header({ commit }, data) {
    //     commit('REMOVE_ITEM_HEADERS', data);
    // },
    // clear_headers({ commit }) {
    //     commit('CLEAR_HEADERS', data);
    // },
    // add_element({ commit }, data) {
    //     commit('SET_ELEMENT_ARR', data);
    // },
    // remove_item_ds_table({ commit }, data) {
    //     commit('REMOVE_ITEM_DS_TABLE', data);
    // },
    // clear_element({ commit }) {
    //     commit('CLEAR_ELEMENT_ARR');
    // },
    get_file({ commit }, entity) {
        return ReportService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_pdf({ commit }, entity) {
        return ReportService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.data);
            },
            error => {
                console.log(error)
                commit('SET_ITEM_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_collection_entity({ commit }, entity) {
        return ReportService.get_entity(entity).then(
            response => {
                commit('SET_ARR_ENTITY_SOURCES', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_ARR_ENTITY_SOURCES', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_entity({ commit }, entity) {
        return ReportService.get_entity(entity).then(
            response => {
                commit('SET_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_ENTITY', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_entity_callback({ commit }, entity) {
        return ReportService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    post_entity({ commit }, data) {
        return ReportService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_entity_callback_uuid({ commit }, data) {
        return ReportService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ status: response.status, data: { uuid: response.data.data.uuid } });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_entity_callback({ commit }, data) {
        return ReportService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ status: response.status, data: response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    add_images({ commit }, data) {
        let item = data.item;
        return ReportService.upload_images(item, data.entity).then(
            response => {
                commit('SET_IMAGES', []);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_IMAGES', []);
                return Promise.reject(error);
            }
        );
    },
    delete_entity({ commit }, data) {
        return ReportService.delete_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_entity({ commit }, data) {
        let item = data.item;
        return ReportService.update_entity(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_text_photos({ commit }, data) {
        let item = data.item;
        return ReportService.update_entity(item, data.entity).then(
            response => {
                commit('SET_IMAGES', []);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_IMAGES', []);
                return Promise.reject(error);
            }
        );
    }
}

const mutations = {
    SET_HEADERS(state, data) {
        state.headers_table = data;
    },
    CLEAR_HEADERS(state) {
        state.headers_table = [];
    },
    REMOVE_ITEM_DS_TABLE(state, index) {
        state.headers_table.splice(index, 1);
    },
    SET_DS_TABLE(state, data) {
        state.ds_table = data;
    },
    // SET_ELEMENT_ARR(state, data) {
    //     state.ds_data_element.elements.push(data);
    // },
    // REMOVE_ITEM_ELEMENT_ARR(state, index) {
    //     state.ds_data_element.elements.splice(index, 1);
    // },
    // CLEAR_ELEMENT_ARR(state) {
    //     state.ds_data_element.elements = [];
    //     state.ds_data_element.headers = [];
    //     localStorage.removeItem('temp_data_arr');
    // },
    SET_ARR_ENTITY_SOURCES(state, res) {
        console.log("inspectores module store")
        let val_entity = res.entity.toString().split("/");
        switch (val_entity[0]) {
            case "pipeline":
                state.pipetemplate_tbl = res.response;
                break;
            case "linea_produccion":
                state.production_line = res.response;
                break;
            case "orden_servicio":
            case "reporte_servicio":
                state.order_services = res.response;
                break;
            case "clientes":
                state.clients = res.response;
                break;
            case "base_cliente":
                state.client_base = res.response;
                break;
            case "equipos":
                state.equipment = res.response;
                break;
            case "inspecciones_end":
                state.reports = res.response;
                break;
            case "categorias_equipo":
                state.categories = res.response;
                break;
            case "accesorios_equipo":
                state.accessories = res.response;
                break;
            case "metodos_inspeccion":
                state.methods_end = res.response;
                break;
            case "norma_referencia":
            case "normas_referencia":
                state.reference_norms = res.response;
                break;
            case "criterio_evaluacion":
            case "criterios_evaluacion":
                state.criterios = res.response;
                break;
            case "procedimiento":
            case "procedimientos":
                state.procedures = res.response;
                break;
            case "herramienta_inspeccion":
            case "herramientas_inspeccion":
                state.tools_inspection = res.response;
                break;
            case "estados_recubrimiento":
                state.surface_states = res.response;
                break;
            case "tipos_recubrimiento":
                state.coating_types = res.response;
                break;
            case "tipos_material":
                state.material_types = res.response;
                break;
            case "tipos_iluminacion":
                state.lighting_type = res.response;
                break;
            case "inspectores":
                state.inspector = res.response;
                break;
            case "supervisores":
                state.supervisor = res.response;
                break;
            case "evidencia_reporte":
                state.images_report = res.response
        }
    },
    SET_ENTITY(state, res) {
        let val_entity = res.entity.toString().split("/");
        switch (val_entity[0]) {
            case "pipeline":
                state.item_report_master = res.response;
                break;
            case "custom_field": 
            case "orden_servicio":
                state.item_order_service = res.response;
                break;
            case "reporte_master":
                state.item_report_master = res.response;
                break;
            case "catalogos_reporte":
                state.catalog = res.response;
                break;
            case "cliente":
                state.item_equipment = res.response;
                break;
            case "equipo":
                state.item_equipment = res.response;
                break;
            case "inspeccion_end":
                state.item_equipment = res.response;
                break;
            case "categoria_equipo":
                state.categories = res.response;
                break;
            case "accesorio_equipo":
                state.item_equipment = res.response;
                break;
            case "metodo_inspeccion":
                state.item_equipment = res.response;
                break;
            case "norma_referencia":
                state.item_reference_norm = res.response;
                break;
            case "criterio_evaluacion":
                state.item_equipment = res.response;
                break;
            case "procedimiento":
                state.item_equipment = res.response;
                break;
            case "herramienta_inspeccion":
                state.item_equipment = res.response;
                break;
            case "estado_recubrimiento":
                state.item_equipment = res.response;
                break;
            case "tipo_recubrimiento":
                state.item_equipment = res.response;
                break;
            case "tipo_material":
                state.item_equipment = res.response;
                break;
            case "tipo_iluminacion":
                state.item_equipment = res.response;
                break;
            case "inspector":
                state.item_equipment = res.response;
                break;
            case "certificado_inspeccion":
                state.item_certificate = res.response;
                break;
        }
    },
    SET_IMAGES(state, res) {
        state.images_report = res;
    },
    SET_SUCCESS(state, success) {
        state.success = success;
    }
}

export const reports = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
