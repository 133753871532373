import Vue from 'vue'
import App from './App.vue'
// import './firebase'
import './registerServiceWorker'
import router from './router';
import moment from 'moment';
import vuetify from './plugins/vuetify'
import store from './store/store';
import interceptors from './services/interceptors';
import filters from './tools/filters';
import getPermissions from './tools/permissions'
import getModules from './tools/modules'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import location from '@/tools/geoposition'
import * as VueGoogleMaps from "vue2-google-maps";
import Select2 from 'v-select2-component';

import './assets/css/style.css'
moment.locale("es-MX");
interceptors();
filters();
//messaging.getToken;
getPermissions();
getModules();
//location.tools.get_location();

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.component(Select2);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyATzeQeNBZy3-uF81YbOWwO_0umEn5HqeU",
  },
});
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
