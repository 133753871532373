import Repository from '../services/repository.services'
const PREFIX = 'inventory/';

const state = {
    listProducts: [],
    product: {},
    listOrders: [],
    listOSShopping: [],
    success: false,
}

const getters = {
    getListProducts: (state) => state.listProducts,
    getItemProduct: (state) => state.product,
    getOrderSales: (state) => state.listOrders,
    getOrderShopping: (state) => state.listOSShopping,
}
const actions = {
    create({ commit }, data){
        return Repository.post(data.item,`${PREFIX}${data.entity}`).then(        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getStock({ commit }) {
        return Repository.get(`${PREFIX}stock`).then(
            response => {              
                commit('SET_LIST', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getUpdate({ commit }, datos){
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getOrders({ commit }) {
        return Repository.get(`${PREFIX}orders`).then(
            response => {              
                commit('SET_ORDES', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_ORDES', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getShoppingOrders({ commit }) {
        return Repository.get(`${PREFIX}shopping_orders`).then(
            response => {              
                commit('SET_SHOPPING_ORDES', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SHOPPING_ORDES', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    update_quantity({ commit }, data){
        return Repository.update(data.item,`${PREFIX}${data.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
}
const mutations = {
    SET_LIST(state, data) {
        console.log("resultado stock: ", data)
        state.listProducts = data;
    },
    SET_ITEM(state, data) {
        state.product = data;
    },
    SET_ORDES(state, data){
        state.listOrders = data;
    },
    SET_SHOPPING_ORDES(state, data){
        state.listOSShopping = data;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const inventory = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}