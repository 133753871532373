import AdminService from '../services/admin.services'


const state = {
    objExel: {},
    listData: [],
    itemData: {},
}

const getters = {
    getDataExcel:(state) => state.objExel,
    getDataList:(state) => state.listData, 
    getDataItem:(state) => state.itemData, 
}
const actions = {
    get_list({ commit }, entity) {
        return AdminService.get_entity(entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_LIST_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_item({ commit }, entity) {
        return AdminService.get_entity(entity).then(
            response => {
                commit('SET_ITEM_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_ITEM_ENTITY', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    post({ commit }, data) {
        return AdminService.post_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data) {
        return AdminService.delete_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, data) {
        let item = data.item;
        return AdminService.update_entity(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    upload_file({ commit }, data){       
        console.log("upload") 
        return AdminService.upload_file(data.item, data.entity).then(
            response => {
                commit('SUCCESS_UPLOAD_EXCEL', response.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SUCCESS_UPLOAD_EXCEL', null);
                return Promise.reject(error);
            }
        );
    }
}
const mutations = {
    SUCCESS_UPLOAD_EXCEL(state, res){
        state.objExel = res;
    },
    SET_LIST_ENTITY(state, res) {
        state.listData = res.response;
    },
    SET_ITEM_ENTITY(state, res) {
        state.itemData = res.response;
    },
    SET_SUCCESS(state, success) {
        state.success = success;
    }
}

export const catalogs = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

