<template>
  <div class="d-flex align-self-center">
    <div class="d-flex justify-start align-self-center breadcrumbs">
      <v-icon color="#ffff" @click="navigateToHome"> mdi-home </v-icon>
      <v-icon v-if="parent" color="#ffff"> mdi-chevron-right </v-icon>
      <v-card-subtitle
        class="text-breadcrumbs"
        v-if="parent"
        @click="navigateToParent"
      >
        {{ parent.name }}
      </v-card-subtitle>
      <v-icon color="#ffff" v-if="currentRouteName"> mdi-chevron-right </v-icon>
      <v-card-subtitle v-if="currentRouteName">
        {{ currentRouteName }}
      </v-card-subtitle>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: ["parent"],
  data: () => ({
    host: "Inicio",
    history: null,
  }),
  methods: {
    navigateToHome() {
      this.$router.push("/");
    },
    navigateToParent() {
      console.log("this.parent===", this.parent);
      this.$router.push(this.parent.view);
    },
  },

  computed: {
    currentRouteName() {
      // console.log("this.parent.name-----", this.parent);
      // console.log("this.$route.name ", this.$route.name);
      return this.$route.name != this.parent.name ? this.$route.name : null;
    },
    user() {
      return this.$store.getters["authenticate/getDataUser"];
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
a,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}
.v-application,
.text-h6,
.text-overline {
  font-family: "Poppins", sans-serif !important;
}
.v-application a,
.breadcrumbs {
  background-color: #000000 !important;
  color: #f2f2f2;
}
.text-breadcrumbs {
  cursor: pointer;
}
.text-breadcrumbs:hover {
  color: #ec4036;
}
</style>
