import Repository from '../services/repository.services'
const PREFIX = 'paysheet/';

const state = {
    paysheet: [],
    paysheetPdf:{},
    paysheet_edit: {},
    paysheet_doc:[],
    paysheet_logs: [],
    payexternal_type: [],
    paysheet_folio:[],
    success: false
}

const getters = {
    getPaysheet: (state) => state.paysheet,
    getPaysheetItem: (state) => state.paysheet_edit,
    getPaysheetDoc: (state) => state.paysheet_doc,
    getPaysheetLogs: (state) => state.paysheet_logs,
    getPaysheetPDF: (state) => state.paysheetPdf,
    getExternalType: (state) => state.payexternal_type,
    getPaysheetFolio: (state) => state.paysheet_folio,
}
const actions = {
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, response:response.data});
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    
    postItem({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        let item = data.item;
        return Repository.delete(item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpload({ commit }, datos) {
        let item = datos.item;
        return Repository.upload(item,`${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "paysheet_filter":
                    state.paysheet = res.response;
                    break;
            }
        }
    },
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "paysheet_doc":
                    state.paysheet_doc = res.response.data;
                    break;
                case "paysheet_logs":
                    state.paysheet_logs = res.response.data;
                    break;
                case "payexternal_type":
                    state.payexternal_type = res.response.data;
                    break;
                case "paysheet_folio":
                    state.paysheet_folio = res.response.data;
                    break;
            }
        }
    },
    SET_ITEM(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "paysheet":
                    state.paysheet_edit = res.response.data;
                    break;
                case "paysheet_pdf":
                    state.paysheetPdf = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const paysheet = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
