import Repository from '../services/repository.services'
const PREFIX = 'store/';

const state = {
    store: [],
    storeShipment: [],
    storeShipmentProduct: [],
    storeOutput:[],
    success: false
}

const getters = {
    getStoreShipment: (state) => state.storeShipment,
    getStoreShipmentProduct: (state) => state.storeShipmentProduct,
    getstore: (state) => state.store,
    getStoreOutput: (state) => state.storeOutput,
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    postItem({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity,  response:response.data  });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpdate({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "inventory":
                    state.store = res.response.data;
                    break;
                case "inventory_shipment":
                    state.storeShipment = res.response.data;
                    break;
                case "inventory_output":
                    state.storeOutput = res.response.data;
                    break;
                case "inventory_product":
                    console.log('res.response.data',res.response.data)
                    state.storeShipmentProduct = res.response.data;
                    break;
            }
        }
    },
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "inventory_output_filter":
                    state.storeOutput = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
