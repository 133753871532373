
import ClientService from '../services/client.services'

const download = (path, filename) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
};

const state = {
    clientList: [],
    servicesOrderList: [],
    serviceOrder: {},
    certificateList: [],
    certificateItem: {},
    reportList: [],
    reportItem: {},
    success: true,
}
const getters = {
    getCertificateList: (state) => state.certificateList,
    getSOList: (state) => state.servicesOrderList,
    getReportList: (state) => state.reportList,
    getCertificate: (state) => state.certificateItem,
    getReport: (state) => state.reportItem,
    getSO: (state) => state.serviceOrder,
    getclientList: (state) => state.clientList,
}
const actions = {
    get_list({ commit }, entity) {
        return ClientService.get(entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_LIST_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    postDatas({ commit }, data) {
        return ClientService.post(data.item, data.entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_LIST_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_item({ commit }, entity) {
        return ClientService.get(entity).then(
            response => {
                commit('SET_ITEM_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_ITEM_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_pdf({ commit }, entity) {
        return ClientService.get(entity).then(
            response => {
                commit('SET_PDF', response.data);
                return Promise.resolve(response.data);
            },
            error => {
                console.log(error)
                commit('SET_ITEM_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    update({ commit }, data) {
        return ClientService.update(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}
const mutations = {
    SET_SUCCESS(state, success) {
        state.success = success;
    },
    SET_LIST_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "clientes":
                    state.clientList = res.response;
                    break;
                case "orden_servicio": //VUE_APP_API_ENTITY_ORDER_SERVICES
                    state.servicesOrderList = res.response;
                    break;
                case "reporte_servicio":
                    state.reportList = res.response;
                    break;
                case "reporte_master": //VUE_APP_API_ENTITY_REPORT_MASTER
                    state.reportList = res.response;
                    break;
                case "certificado_inspeccion": //VUE_APP_API_ENTITY_CERTIFICATE
                    state.certificateList = res.response;
                    break;
            }
        }
    },
    SET_ITEM_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) { }
        }
    },
    SET_PDF(state, res) {
        state.success = true;
        // const blob = new Blob([res], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);
        // download(url, 'users.pdf');
        // URL.revokeObjectURL(url);
        /***************************** */
        // const url = window.URL.createObjectURL(new Blob([res]))
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute('download', "title-file")
        // document.body.appendChild(link)
        // link.click()
    }
}

export const clients = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
