import { initializeApp } from "firebase/app";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import store from './store/store';

const firebaseConfig = {
  apiKey: "AIzaSyATzeQeNBZy3-uF81YbOWwO_0umEn5HqeU",
  authDomain: "midas-61e45.firebaseapp.com",
  projectId: "midas-61e45",
  storageBucket: "midas-61e45.appspot.com",
  messagingSenderId: "480544305414",
  appId: "1:480544305414:web:6408b064ab7ec9516f6e87",
  measurementId: "G-MKBXHT7WXN",

};


const app = initializeApp(firebaseConfig);


// const messaging = getMessaging(app);

// getToken(messaging, { vapidKey: 'BAhnodxBll6ecEJ4C79KVWmX6l6N8AHx2TZlw-9qSjJfZptwo-VjSmvXyaaP5T2hsc_Lv69BtMU3JgOZd-Z1anY' }).then((currentToken) => {
//   if (currentToken) {
//     console.log("TOKEN ID MIDAS FOR THIS BROWSER");
//     console.log(currentToken);
//     store.dispatch("messaging/set_token", currentToken);
//   } else {
//     console.log('No registration token available. Request permission to generate one.');
//     store.dispatch("messaging/set_token", null);
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   store.dispatch("configurations/notify_error_message", 'An error occurred while retrieving token. ' + err);
// });

// onMessage(messaging, (payload) => {
//   console.log("FMC msg", payload)
//   store.dispatch("messaging/add_message", payload.notification ? payload.notification : payload.data);
// });

// export default { app, messaging, getToken}
export default { app }