<template>
    <div class="sectionError">
        <div class="error404">
            <h1>401.3</h1>
            <h2>Cuenta Suspendida, Favor de contacarse con su Ejecutivo de Ventas</h2>
            <br />
            <span v-if="user && user.cia" class="text-h6 font-weight-bold">{{ user.cia.name }}</span>
            <br />
            <p>
                Puedes volver
                <a @click="$router.back()" color="secondary" dark> aquí.</a>
            </p>
        </div>

        <div>
            <v-img :src="image_header" contain></v-img>
        </div>
    </div>
    <!-- <v-container fluid fill-height>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-icon color="primary" class="mt-5" size="160">
            mdi-shield-lock
          </v-icon>
          <span class="text-h5 text-uppercase"> 401 Acceso no autorizado </span>
          <v-btn @click="$router.back()" color="primary" text>
            <v-icon left>mdi-arrow-left-bold-circle</v-icon> Regresar.
          </v-btn>
          <v-btn to="/" color="secondary" text>
            <v-icon left>mdi-home</v-icon> Ir a página principal.
          </v-btn>
          <v-col cols="12" md="12">
            <v-img :src="image_header" height="160" contain></v-img>
          </v-col>
        </v-row>
      </v-container> -->
</template>
  
<script>
export default {
    name: "NotFound",
    data: () => ({
        image_header: `${process.env.VUE_APP_STORAGE_PUBLIC}/midgrab/error404_logo.png`,
    }),
    computed: {
        user() {
            return this.$store.getters["authenticate/getDataUser"];
        },
    }
};
</script>
  
<style scoped>
.main-notfound {
    background-color: #ffff;
}

.sectionError {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 70vh;
}

.error404>h1 {
    font-size: 150px;
    font-weight: bold;
    margin: 0px;
    color: #222831;
}

.error404>p {
    font-size: 18px;
}
</style>
  