import Repository from '../services/repository.services'
const PREFIX = 'filter/';

const state = {
    clientes:[],
    pedido: [],
    quotes: [],
    product: [],
    unit: [],
    supervisor:[],
    // paysheet_folio: [],
    success: false
}

const getters = {
    getClientes: (state) => state.clientes,
    getPedidoFolio: (state) => state.pedido,
    getCotFolio: (state) => state.quotes,
    getProduct: (state) => state.product,
    getUnitM: (state) => state.unit,
    getSupervisor: (state) => state.supervisor
    // getPaysheetFolio: (state) => state.paysheet_folio,
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },

    getAutocomplete({ commit }, data) {
        return Repository.autocomplete(`${PREFIX}${data.entity}`, {term: data.term}).then(
            response => {
                commit('SET_FILTER', { entity: data.entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_FILTER', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "client":
                    state.clientes = res.response.data;
                    break;
                case "pedido":
                    state.pedido = res.response.data;
                    break;
                case "quotes":
                    state.quotes = res.response.data;
                    break;
                case "product":
                    state.product = res.response.data;
                    break;
                case "unit":
                    state.unit = res.response.data;
                    break; 
                case "supervisor":
                    state.supervisor = res.response.data;
                    break;     
                    
                // case "paysheet_folio":
                //     state.paysheet_folio = res.response.data;
                //     break;
            }
        }
    },

    SET_FILTER(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "product":
                    state.product = res.response.data;
                    break; 
            }
        }
    },
}

export const filter = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
