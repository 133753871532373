<template>
  <v-alert
    width="550"
    border="left"
    colored-border
    :color="toast.type"
    elevation="2"
    prominent
    :type="toast.type"
    :dismissible="toast.btn_navigate ? false : true"
  >
    <v-row align="center">
      <v-col class="text-left">
        <small class="text-uppercase font-weight-bold">{{ toast.title }}</small>
        <br />
        <span class="text-left font-weight-light">{{ toast.message }}</span>
      </v-col>
      <v-col class="shrink" v-if="toast.btn_navigate">
        
        <v-btn class="ma-2" outlined :color="toast.type" :to="toast.btn_navigate.link">{{
          toast.btn_navigate.label
        }}</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "Toast",
  props: {
    toast: null,
  },
  created() {
    if (this.toast) {
      setTimeout(() => {
        this.dismissToast();
      }, 9000);
    }
  },
  methods: {
    dismissToast() {
      this.$store.dispatch("toasts/clearToast", this.toast.title);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>