<template>
  <div>
    <Breadcrumbs :parent="parentView"
          class="breadcrumbs"
    />
    <!-- <Nabvar
    /> -->
    <v-row>
      <v-col cols="12" md="12" class="mt-10">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Breadcrumbs from "../components/features/Breadcrumbs.vue";
// import Nabvar from "../components/features/Nabvar.vue";
export default {
  components: { 
    Breadcrumbs,
    // Nabvar 
  },
  data: () => ({
    parentView: {name: "Administracion de certificados", view: "/certificados"}
  }),
};
</script>

<style scoped>
.breadcrumbs {
  z-index: 1;
  width: 100%;
  position: fixed;
}
</style>