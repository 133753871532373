<template>
  <div class="sectionError">
    <div class="error404">
      <h1>404</h1>
      <h2>Ups... Parece que algo nos falta.</h2>
      <br />
      <p>Al parecer este URL no se encuentra en este servidor.</p>
      <p>
        Puedes volver
        <a @click="$router.back()" color="secondary" dark> aquí.</a>
      </p>
    </div>

    <div>
      <v-img :src="image_header" contain></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({
    image_header: `${process.env.VUE_APP_STORAGE_PUBLIC}/midgrab/error404_logo.png`,
  }),
};
</script>

<style scoped>
.main-notfound {
  background-color: #ffff;
}
.sectionError {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 70vh;
}

.error404 > h1 {
  font-size: 150px;
  font-weight: bold;
  margin: 0px;
  color: #222831;
}
.error404 > p {
  font-size: 18px;
}
</style>
