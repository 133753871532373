import Repository from '../services/repository.services'
const PREFIX = 'inspection/';

const state = {
    consecutive: 0,
    listOS: [],
    os: {},
    listOSReports: [],
    osReport: {},
    success: false
}

const getters = {
    getConsecutive: (state) => state.consecutive,
    getListOs: (state) => state.listOS,
    getOs: (state) => state.os,
    getListReportOS: (state) => state.listOSReports,
    getOsReport: (state) => state.osReport,
}

const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    post({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_callback({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ status: response.status, data: response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data) {
        return Repository.delete(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, data) {
        return Repository.update(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    upload({ commit }, data) {
        console.log("upload file")
        return Repository.upload(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "orden_servicio":
                    state.listOS = res.response;
                    break;
                case "reporte_servicio":
                    state.listOSReports = res.response;
                    break;
            }
        }
    },
    SET_ITEM(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "orden_servicio":
                    state.os = res.response;
                    break;
                case "reporte_servicio":
                    state.osReport = res.response;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    }
}

export const inspection = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}