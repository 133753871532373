import axios from 'axios';
import store from '../store/store';


export default function setup() {

  axios.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('midgrab.app.stk'));

    store.dispatch("configurations/open_loading");

    if (token.acstapp) {
      let cia = token.data && token.data.cia_uuid ? token.data.cia_uuid : "host";
      config.headers.Authorization = `Bearer ${token.acstapp}`;
      config.headers.codeuuid = token.data && token.data.uuid ? token.data.uuid : "anonymous";
      config.headers.Requesterby = token.data && token.data.name ? `${token.data.name},${token.data.email},${token.data.rol_code},${cia}` : "anonymous";
    }
    else {

      setTimeout(() => store.dispatch('configurations/close_loading'), 1000);

    }
    return config

  }, function (err) {
    return Promise.reject(err);
  });


  axios.interceptors.response.use(
    response => {
      if (response.status === 200 || response.status === 201) {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1000);
        return Promise.resolve(response);
      } else {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1000);
        return Promise.reject(response);
      }
    },
    error => {
      if (error && error.response && error.response.status) {
        switch (error.response.status) {
          case 400:
            if (error.response.data && error.response.data.error && error.response.data.error == "token is invalid") {
              store.dispatch("authenticate/notify_token_invalid");
            }
            else if (error.response.data && error.response.data.error && error.response.data.error == "token is expired") {
             store.dispatch("toasts/notify_need_authenticate");
            }
            console.log("error 400 " + error.response.statusText);
            break;
          case 401:
            error.response.data.error == 'Unauthorized' ? store.dispatch("toasts/notify_custom", error.response.data.message) : store.dispatch("toasts/notify_error_message", error.response.data.message);
            break;
          case 403:
            console.log("error 403 " + error.response.statusText);
            break;
          case 404:
            console.log("error 404 " + error.response.statusText);
            break;
          case 500:
            store.dispatch("toasts/addToast", {
              title: "Error en la petición",
              type: "error",
              message: error.response.statusText.length > 0 ? error.response.statusText : error.response.data.error
            });
            //store.dispatch("configurations/notify_error_message", error.response.statusText.length > 0 ?error.response.statusText : error.response.data.error );
            break;
          case 502:
            console.log("error 502 " + error.response.statusText);
        }
        setTimeout(() => store.dispatch('configurations/close_loading'), 2200);
        return Promise.reject(error.response);
      }
      else {
        setTimeout(() => store.dispatch('configurations/close_loading'), 2200);
      }
    }
  );
}