import { update } from 'lodash';
import Repository from '../services/repository.services'
const PREFIX = 'sales/';

const state = {
    orderBoardingList: [],
    orderBoarding: {},
    vehicleList: {},
    operatorsList: {},
    quote: {},
    success: false
}

const getters = {
    getListOrders: (state) => state.orderBoardingList,
    getOrder: (state) => state.orderBoarding,
    getQuote: (state) => state.quote,
    getListVehicles: (state) => state.vehicleList,
    getListOperators: (state) => state.operatorsList,
}
const actions = {
    createOrder({ commit }, data) {
        return Repository.post(data, `${PREFIX}embarque`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getOrderByPO({ commit }, uuid){
        console.log("UUID de orden: ", uuid)
        return Repository.get(`${PREFIX}embarque/orden_pedido/${uuid}`).then(            
            response => {
                commit('SET_ORDERS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, data) {
        return Repository.update(data, `${PREFIX}embarque`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data) {
        return Repository.delete(data, `${PREFIX}embarque`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    addVehicle({ commit }, data) {
        return Repository.post(data, `${PREFIX}embarque_unidad`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    addOperator({ commit }, data) {
        return Repository.post(data, `${PREFIX}operador`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getOrder({ commit }, uuid){
        return Repository.get(`${PREFIX}embarque/${uuid}`).then(            
            response => {
                commit('SET_ORDER', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getOrders({ commit }){
        return Repository.get(`${PREFIX}embarque`).then(            
            response => {
                commit('SET_ORDERS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getOrdersQry({ commit }, data){
        return Repository.post(data,`${PREFIX}embarque/query`).then(            
            response => {
                commit('SET_ORDERS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    addCost({ commit }, data){
        return Repository.post(data,`${PREFIX}costo/embarque`).then(            
            response => {
                commit('SET_ORDERS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getVehicles({ commit }){
        return Repository.get(`${PREFIX}embarque_unidad`).then(            
            response => {
                commit('SET_VEHICLES', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getOperators({ commit }){
        return Repository.get(`${PREFIX}operador`).then(            
            response => {
                commit('SET_OPERATORS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    quote({ commit }, uuid){
        return Repository.get(`${PREFIX}embarque/cotizacion/${uuid}`).then(            
            response => {
                commit('SET_QUOTE', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getPDF({ commit }, uuid){
        return Repository.get(`${PREFIX}pdf/embarque/${uuid}`).then(            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },

}

const mutations = {
    SET_QUOTE(state, res) {
        state.quote = res.data.data;
    },
    SET_ORDER(state, res) {
        state.orderBoarding = res.data.data;
    },
    SET_ORDERS(state, res) {
        state.orderBoardingList = res.data.data;
    },
    SET_VEHICLES(state, res) {
        state.vehicleList = res.data.data;
    },
    SET_OPERATORS(state, res) {
        state.operatorsList = res.data.data;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const boarding = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}