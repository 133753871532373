import moment from 'moment';

const accepted = JSON.parse(localStorage.getItem('midgrab.accept.cookies.privacy.user'));

const state = {
    terms_accepted: accepted ? accepted : undefined,
    isbussy: false,
    success_message: "",
    show_message: false,
    navigate_data: {},
    modules: [],
}

const getters = {
    getaAvertisement: (state) => state.terms_accepted,
    getLoading: (state) => state.isbussy,
    getMessage: (state) => state.success_message,
    getVisibility: (state) => state.show_message,
    getNavigateData: (state) => state.navigate_data,
    getModules: (state) => state.modules,
}

const actions = {
    setModules({ commit }, data) {
        commit('SET_MODULES', data);
    },
    setNavigateData({ commit }, data) {
        commit('SET_NAVIGATE_DATA', data);
    },
    open_loading({ commit }) {
        commit('SET_LOADING', true);
    },
    close_loading({ commit }) {
        commit('SET_LOADING', false);
    },
    set_accept_advertisement_cookies({ commit }) {
        commit('SET_ACCEPT_COKKIES_PRIVACY', {
            date: moment().format('DD/MM/YYYY hh:mm'),
            accept: true
        });
    },
    hidden_message({ commit }) {
        commit('HIDE_MESSAGE_SUCCES');
    },
    notify_saved({ commit }) {
        commit('SET_NOTIFY', "CREATED");
    },
    notify_upated({ commit }) {
        commit('SET_NOTIFY', "UPDATED");
    },
    notify_error_get({ commit }) {
        commit('SET_NOTIFY', "NOT_READ");
    },
    notify_error_saved({ commit }) {
        commit('SET_NOTIFY', "NOT_CREATED");
    },
    notify_error_updated({ commit }) {
        commit('SET_NOTIFY', "NOT_UPDATED");
    },
    notify_error_message({ commit }, msg) {
        commit('SET_NOTIFY', msg);
    },
    notify_error_form({ commit }) {
        commit('SET_NOTIFY', "ERROR_FORM");
    },
    notify_deleted({ commit }) {
        commit('SET_NOTIFY', "DELETED");
    },
    notify_error_action({ commit }) {
        commit('SET_NOTIFY', "ERROR_ACTION");
    },

}

const mutations = {
    SET_MODULES(state, data){
        state.modules = data;
    },
    SET_NAVIGATE_DATA(state, data){
        state.navigate_data = data;
    },
    SET_ACCEPT_COKKIES_PRIVACY(state, data) {
        state.advertisement = data;
        localStorage.setItem('midgrab.accept.cookies.privacy.user', JSON.stringify(data));
    },
    SET_LOADING(state, _isbussy) {
        state.isbussy = _isbussy;
    },
    SET_NOTIFY(state, success) {
        switch (success) {
            case "NOT_READ":
                state.success_message = "La consulta no se realizó de forma correcta, por favor intente de nuevo o reportelo con el administrador del sistema.";
                state.show_message = true;
                break;
            case "ERROR_ACTION":
                state.success_message = "La acción solicitada no pudo completarse correctamente, por favor intente de nuevo o reportelo con el administrador del sistema.";
                state.show_message = true;
                break;
            case "DELETED":
                state.success_message = "El registro fué eliminado con éxito.";
                state.show_message = true;
                break;
            case "CREATED":
                state.success_message = "El registro fué creado con éxito.";
                state.show_message = true;
                break;
            case "UPDATED":
                state.success_message = "El registro fué actualizado con éxito.";
                state.show_message = true;
                break;
            case "NOT_CREATED":
                state.success_message = "El registro no pudo ser creado, por favor intente nuevamente o reportelo al administrador de sistemas.";
                state.show_message = true;
                break;
            case "NOT_UPDATED":
                state.success_message = "El registro no pudo ser acualizado, por favor intente nuevamente o reportelo al administrador de sistemas.";
                state.show_message = true;
            break;
            case "ERROR_FORM":
                state.success_message = "Faltan campos con información o la informacion no es correcta. Favor de verificar los campos marcados em rojo.";
                state.show_message = true;
                break;
            default:
                state.success_message = success;
                state.show_message = true;
                break;
        }

    },
    HIDE_MESSAGE_SUCCES(state) {
        state.success_message = null;
        state.show_message = false;
    },
}

export const configurations = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}