import axios from 'axios';
import store from '@/store/store';
const API_URL = process.env.VUE_APP_SERVICES_HOST;

const unintercepted_axiosInstance = axios.create();


unintercepted_axiosInstance.interceptors.request.use(function (config) {

    const token = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    store.dispatch("configurations/open_loading");

    if (token.acstapp) {
        let cia = token.data && token.data.cia_uuid ? token.data.cia_uuid : "host";
        config.headers.Authorization = `Bearer ${token.acstapp}`;
        config.headers.codeuuid = token.data && token.data.uuid ? token.data.uuid : "anonymous";
        config.headers.Requesterby = token.data && token.data.name ? `${token.data.name},${token.data.email},${token.data.rol_code},${cia}` : "anonymous";
      }
      else {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1000);
      }
    return config

}, function (err) {
    return Promise.reject(err);
});

unintercepted_axiosInstance.interceptors.response.use(
    response => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.resolve(response);
    },
    error => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.reject(error.response);
    });

class UIService {
    get(entity) {        
        return unintercepted_axiosInstance.get(`${API_URL}${entity}`);
    }
    post(payload, entity) {        
        return unintercepted_axiosInstance.post(`${API_URL}${entity}`, payload);
    }
}

export default new UIService();