import Repository from '../services/repository.services'
const PREFIX = 'orders/';
const state = {
    orders: [],
    partial: [],
    documents:[],
    comentariosOrders: [],
    orderLog:[],
    orderItem:{},
    success: false
}

const getters = {
    getOrders: (state) => state.orders,
    getItemOrders: (state) => state.orderItem,
    getDocuments: (state) => state.documents,
    getComentsOders: (state) => state.comentariosOrders,
    getLog: (state) => state.orderLog,
    getPartial: (state) => state.partial,
}

const actions = {
    getUpdate({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getDoc({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
        
            response => {
                commit('SET_LIST_DOC', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST_DOC', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getComments({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
        
            response => {
                commit('SET_LIST_COMMENTS', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST_COMMENTS', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItemLog({ commit }, entity) {
        // return commit('SET_ITEM_LOG', { datos: comentarios});
        return Repository.get(`${PREFIX}${entity}`).then(
        
            response => {
                commit('SET_ITEM_LOG', { entity: entity, datos: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM_LOG', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    // Comentario 
    getItem({ commit }, comentarios) {
        return commit('SET_ITEM', { datos: comentarios});
    },
    saveCommentsOrders({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({response:response.status, datos:response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    commentSave({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    get_entity_callback({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
        
            response => {
                commit('SET_SUCCESS', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPartial({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
        
            response => {
                commit('SET_LIST_PARTIAL', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST_PARTIAL', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        return Repository.delete(data.item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, datos:response.data.data  });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        state.orders = res.response;
        // if (res) {
        //     let val_entity = res.entity.toString().split("/");
        //     console.log('val_entity',res.entity)
        //     switch (val_entity[0]) {
        //         case "orden_pedido":
        //             state.orders = res.response;
        //             break;
        //         case "document":
        //             console.log('res set_list',res.response)
        //             state.documents = res.response;
        //             break;
        //     }
            
        // }
    },
    SET_LIST_DOC(state, res){
        state.documents = res.response;
    },
    SET_LIST_COMMENTS(state, res){
        state.comentariosOrders = res.response;
    },
    SET_ITEM(state, res) {
        state.orderItem = res.datos;
    },
    SET_ITEM_LOG(state, res) {
        state.orderLog = res.datos;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
    SET_LIST_PARTIAL(state, res) {
        state.partial = res.response;
    },
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "orden_pedido_filter":
                    state.orders = res.datos;
                    break;
            }
        }
    },
}

export const orders = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
