import SalesService from '../services/sales.services'

const state = {
    executives_list: [],
    type_documents: [],
    clients_list: [],
    production_line: [],
    customer_respresentatives_list: [],
    customer_quotes_list: [],
    services_products_list: [],
    commercialization_list: [],
    product_list: [],
    supplier_list: [],
    items_quote_list: [],
    brand_products_list: [],
    quote_conditions_list: [],
    values_banxico: [],
    companies: [],
    account_banks: [],
    item_quote: {},
    item_line_credit: {},
    objsonEdit: undefined,
}

const getters = {
    getJsonEdit: (state) => state.objsonEdit,
    getTypeDocuments: (state) => state.type_documents,
    getClients: (state) => state.clients_list,
    getProductionLine: (state) => state.production_line,
    getQuotes: (state) => state.customer_quotes_list,
    getExecutivesList: (state) => state.executives_list,
    getServicesProducts: (state) => state.services_products_list,
    getItemsQuotes: (state) => state.items_quote_list,
    getBrandProducts: (state) => state.brand_products_list,
    getQuoteConditions: (state) => state.quote_conditions_list,
    getItemQuote: (state) => state.item_quote,
    getListContact: (state) => state.customer_respresentatives_list,
    getlistComercialization: (state) => state.commercialization_list,
    getListProduct: (state) => state.product_list,
    getListSupplier: (state) => state.supplier_list,
    getListCompanies: (state) => state.companies,
    getListAccountBanks: (state) => state.account_banks,
    getBanxicoData: (state) => state.values_banxico,
    getCreditLineData: (state) => state.item_line_credit,
}

const actions = {
    get_list_entity({ commit }, entity) {
        return SalesService.get_entity(entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    get_item_entity({ commit }, entity) {
        return SalesService.get_entity(entity).then(
            response => {
                commit('SET_ITEM_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_ITEM_ENTITY', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    get_entity_callback({ commit }, entity) {
        return SalesService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    post_entity({ commit }, data) {
        return SalesService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_entity_backstore({ commit }, data) {
        return SalesService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_entity_callback({ commit }, data) {
        return SalesService.create_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete_entity({ commit }, data) {
        return SalesService.delete_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_entity_callback({ commit }, data) {
        let item = data.item;
        return SalesService.update_entity(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_entity_backstore({ commit }, data) {
        let item = data.item;
        return SalesService.update_entity(item, data.entity).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_entity({ commit }, data) {
        let item = data.item;
        return SalesService.update_entity(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    upload_file({ commit }, data) {
        let item = data.item;
        return SalesService.upload_file(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ "status": response.status, "file": response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    setObjectEdit({ commit }, data) {
        commit('SET_OBJ_EDIT', data);
    },
    query_filter({ commit }, data) {
        return SalesService.query_filter(data.item, data.entity).then(
            response => {
                //commit('SET_SUCCESS', true);
                commit('SET_LIST_ENTITY', { entity: data.entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}
const mutations = {
    SET_LIST_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "linea_produccion":
                    state.production_line = res.response.data;
                    break;
                case "tipo_documentos":
                    state.type_documents = res.response.data;
                    break;
                case "cuentas_spei":
                    state.account_banks = res.response.data;
                    break;
                case "companies":
                    state.companies = res.response.data;
                    break;
                case "banxico_exchanges":
                    state.values_banxico = res.response.data;
                    break;
                case "cotizacion":
                    state.customer_quotes_list = res.response.data;
                    console.log('res.response',res.response)
                    if (res.response.unique) {
                        state.executives_list = res.response.unique;
                    }
                    break;
                case "clientes":
                    state.clients_list = res.response.data;
                    break;
                case "representante_cliente":
                    state.customer_respresentatives_list = res.response.data;
                    break;
                case "servicio_producto":
                    state.services_products_list = res.response.data;
                    break;
                case "partida":
                    state.items_quote_list = res.response.data;
                    break;
                case "marca_producto":
                    state.brand_products_list = res.response.data;
                    break;
                case "condiciones_cotizacion":
                    state.quote_conditions_list = res.response.data;
                    break;
                case "comercializacion":
                    state.commercialization_list = res.response.data;
                    break;
                case "producto":
                    state.product_list = res.response.data;
                    break;
                case "proveedor":
                    state.supplier_list = res.response.data;
                    break;
            }
        }
    },
    SET_ITEM_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "linea_credito":
                    state.item_line_credit = res.response;
                    break;
                case "cotizacion":
                    state.item_quote = res.response;
                    break;
                case "clientes":
                    state.clients_list = res.response;
                    break;
                case "representante_cliente":
                    state.customer_respresentatives_list = res.response;
                    break;
                case "servicio_producto":
                    state.services_products_list = res.response;
                    break;
                case "partidas":
                    state.items_quote_list = res.response;
                    break;
                case "marca_producto":
                    state.brand_products_list = res.response;
                    break;
                case "condiciones_cotizacion":
                    state.quote_conditions_list = res.response;
                    break;
            }
        }
    },
    SET_OBJ_EDIT(state, res) {
        if (res) {
            state.objsonEdit = res;
        }
    },
    SET_SUCCESS(state, success) {
        state.success = success;
    }
}

export const sales = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

