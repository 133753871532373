import AdminService from '../services/admin.services'
const PREFIX = 'admin/';

const state = {
    user: {},
    users: [],
    clients_list: [],
    clienteItem: {},
    clienteItemContact:[],
    clienteItemContactDetalle:  [],
    type_contact:[],
    lineaItem:[],
    lineaItemDetalle: [],
    documentRequered: [],
    clienteLog: [],
    clienteComments: [],
    clienteDocuments: [],
    typeDocuments: [],
    roles: [],
    modules: [],
    user_modules: [],
    user_permissions: [],
    permissions: [],
    permission_roles: [],
    credit_lines: [],
    item_line_credit: {},
    credit_request: [],
    item_credit_request: {},
    objExel: {},
    ejecutivos: [],
    chartQuote:{},
    location:[],
}

const getters = {
    getDataExcel:(state) => state.objExel,
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    getClients: (state) => state.clients_list,
    getModules: (state) => state.modules,
    getUserModules: (state) => state.user_modules,
    getUserPermissions: (state) => state.user_permissions,
    getRoles: (state) => state.roles,
    getPermissions: (state) => state.permissions,
    getPermissionRoles: (state) => state.permission_roles,
    getCreditLines: (state) => state.credit_lines,
    getCreditLine: (state) => state.item_line_credit,
    getCreditRequest: (state) => state.credit_request,
    getItemCreditRequest: (state) => state.item_credit_request,
    getItemClient: (state) => state.clienteItem,
    getItemContact: (state) => state.clienteItemContact,
    getItemContactDetalle: (state) => state.clienteItemContactDetalle,
    getTypeContact: (state) => state.type_contact,
    getClientLog: (state) => state.clienteLog,
    getClientComments: (state) => state.clienteComments,
    getClientDocuments: (state) => state.clienteDocuments,
    getTypeDocuments:(state) => state.typeDocuments,
    getLinea:(state) => state.lineaItem,
    getLineaDetalle:(state) => state.lineaItemDetalle,
    getDocReq: (state) => state.documentRequered,
    getExecutive: (state) => state.ejecutivos,
    getchartQuote: (state) => state.chartQuote,
    getLocation: (state) => state.location
}
const actions = {
    get_list_entity({ commit }, entity) {
        return AdminService.get_entity(entity).then(
            response => {
               // console.log('response.data.data get_list_entity ===',response.data.data)
                commit('SET_LIST_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST_ENTITY', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    get_item_entity({ commit }, entity) {
        return AdminService.get_entity(entity).then(
            response => {
                commit('SET_ITEM_ENTITY', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM_ENTITY', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    post_entity({ commit }, data) {
        return AdminService.post_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete_entity({ commit }, data) {
        return AdminService.delete_entity(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update_entity({ commit }, data) {
        let item = data.item;
        return AdminService.update_entity(item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    upload_file({ commit }, data){        
        return AdminService.upload_file(data.item, data.entity).then(
            response => {
                commit('SUCCESS_UPLOAD_EXCEL', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SUCCESS_UPLOAD_EXCEL', null);
                return Promise.reject(error);
            }
        );
    },
    uploadFile({ commit }, data) {
        return AdminService.upload_file(data.item, data.entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ "status": response.status, "file": response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    get_entity_callback({ commit }, entity) {
        return AdminService.getPDF(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_SUCCESS', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },

    getList({ commit }, entity) {
        return AdminService.get_entity(entity).then(
            response => {
               // console.log('response.data.data get_list_entity ===',response.data.data)
                commit('SET_LIST', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', null);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
}
const mutations = {
    SUCCESS_UPLOAD_EXCEL(state, res){
        state.objExel = res ? res.response: {};
    },
    SET_LIST_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "usuarios":
                    state.users = res.response;
                case "clientes":
                    state.clients_list = res.response;
                case "roles":
                    state.roles = res.response;
                    break;
                case "permisos":
                    state.permissions = res.response;
                    break;
                case "usuario_permisos":
                    state.user_permissions = res.response;
                    break;
                case "modulos":
                    state.modules = res.response;
                    break;
                case "usuario_modulos":
                    state.user_modules = res.response;
                    break;
                case "linea_credito":
                    state.credit_lines = res.response;
                    break;
                case "solicitud_credito":
                    state.credit_request = res.response;
                    break;
                case "ejecutivos":
                    state.ejecutivos = res.response;
                    break;
            }
        }
    },
    SET_ITEM_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "usuarios":
                    state.user = res.response;
                case "roles":
                    state.roles = res.response;
                    break;
                case "permisos":
                    state.permissions = res.response;
                    break;
                case "permisos_rol":
                    state.permission_roles = res.response;
                    break;
                case "linea_credito":
                    state.item_line_credit = res.response;
                    break;
                case "solicitud_credito":
                    state.item_credit_request = res.response;
                    break;
                case "cliente":
                    state.clienteItem = res.response;
                    break;
                case "cliente_logs":
                    state.clienteLog = res.response;
                    break;
                case "cliente_comments":
                    state.clienteComments = res.response;
                    break;
                case "cliente_documento":
                    state.clienteDocuments = res.response;
                    break;
                case "cliente_contacto":
                    state.clienteItemContact = res.response;
                    break;
                case "cliente_contacto_detalle":
                    state.clienteItemContactDetalle = res.response;
                    break;
                case "contacto_tipo":
                    state.type_contact = res.response;
                    break;
                case "tipo_documentos":
                    state.typeDocuments = res.response;
                    break;
                case "linea_produccion":
                    state.lineaItem = res.response;
                    break;
                case "linea_produccion_detalle":
                    state.clienteItemContactDetalle = res.response;
                    break;
                case "cliente_doc_req":
                    state.documentRequered = res.response;
                    break;
            }
        }
    },
    SET_SUCCESS(state, success) {
        state.success = success;
    },
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "chart":
                    state.chartQuote = res.response;
                    break;
                case "location":
                    state.location = res.response;
                    break;
            }
        }
    }
}

export const admin = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

