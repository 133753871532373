<template>
  <v-app class="tesst">
    <Toasts class="toats" />
    <v-overlay :value="overlay">
      <v-row class="text-center">
        <v-col cols="12">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </v-col>
        <v-col cols="12">
          <span class="text-lds"> Espere un momento por favor</span>
        </v-col>
      </v-row>
    </v-overlay>
    <router-view></router-view>
    <!-- <HomeAdmin /> -->
  </v-app>
</template>
<script>
import Toasts from "./components/features/Toasts.vue";
import { app } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: "App",
  components: {
    Toasts,
  },
  methods: {},
  created() {
    console.log(process.env.VUE_APP_TITLE);
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey:
        "BAhnodxBll6ecEJ4C79KVWmX6l6N8AHx2TZlw-9qSjJfZptwo-VjSmvXyaaP5T2hsc_Lv69BtMU3JgOZd-Z1anY",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("TOKEN ID MIDAS FOR THIS BROWSER");
          console.log(currentToken);
          this.$store.dispatch("messaging/set_token", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, (payload) => {
      this.$store.dispatch("toasts/notify_messagesFMC", payload);
      this.$store.dispatch("messaging/get_messages");
    });
  },
  computed: {
    overlay() {
      return this.$store.getters["configurations/getLoading"];
    },
  },
};
</script>
<style>
#app {
  background-color: #efefef;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  color: #2c3e50;
}
.v-application,
.text-h6,
.text-overline .text-caption {
  font-family: "Poppins", sans-serif !important;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}

/* LOADING */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.text-lds {
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
  color: #fff;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(240, 165, 0);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.8s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.toats,
.dialog-z-index {
  z-index: 999999999999 !important;
}
</style>

<!-- <template>
  <v-app class="tesst">
    <router-view></router-view>
  </v-app>
</template>

<style>
#app {
  background-color: #efefef;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  color: #2c3e50;
}
.v-application,
.text-h6,
.text-overline .text-caption {
  font-family: "Poppins", sans-serif !important;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}
</style> -->
