import axios from 'axios';
import store from '../store';


const API_URL = process.env.VUE_APP_SERVICES_HOST;
const PREFIX = 'admin/';


class AdminService {

    async get_entity(entity) {
        return axios.get(API_URL + PREFIX + entity);
    }

    post_entity(payload, entity){
        return axios.post(API_URL + PREFIX + entity, payload);
    }

    update_entity(payload, entity){
        return axios.put(API_URL + PREFIX + entity, payload);
    }

    delete_entity(payload, entity){
        return axios.delete(API_URL + PREFIX + entity,  { data: payload } );
    }

    upload_file(payload, entity){
        const config = { headers: { "Content-Type": "multipart/form-data" } };        
        return axios.post(API_URL + PREFIX + entity, payload, config);
    }

    getPDF(entity) {        
        return axios.get(`${API_URL}${entity}`);
    }
}

export default new AdminService();
