import Repository from '../services/repository.services'
const PREFIX = 'providers/';

const state = {
    providerSector: [],
    provider: [],
    providerItem:{},
    providerBank: [],
    providerDoc:[],
    providerProductService:[],
    providerDetalleBank:{},
    providerScore:[],
    providerScorePdf:{},
    success: false
}

const getters = {
    getProviderSector: (state) => state.providerSector,
    getProvider: (state) => state.provider,
    getItemProvider: (state) => state.providerItem,
    getItemProviderBank: (state) => state.providerBank,
    getItemProviderProductS: (state) => state.providerProductService,
    getItemProviderDoc: (state) => state.providerDoc,
    getItemProviderDetalleBank: (state) => state.providerDetalleBank,
    getProviderScore: (state) => state.providerScore,
    getProviderScorePDF: (state) => state.providerScorePdf
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    postItem({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getUpload({ commit }, datos) {
        let item = datos.item;
        return Repository.upload(item,`${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpdate({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        let item = data.item;
        return Repository.delete(item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "providers_sector":
                    state.providerSector = res.response.data;
                    break;
                case "providers":
                    state.provider = res.response.data;
                    break;
                case "providers_bank":
                    state.providerBank = res.response.data;
                    break;
                case "providers_product_services":
                    state.providerProductService = res.response.data;
                    break;
                case "providers_doc":
                    state.providerDoc = res.response.data;
                    break;
                case "providers_score":
                    state.providerScore = res.response.data;
                    break;
            }
        }
    },
    SET_ITEM(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "providers":
                    state.providerItem = res.response.data;
                    break;
                case "providers_detalle_bank":
                    state.providerDetalleBank = res.response.data;
                    break;
                case "providers_score_pdf":
                    state.providerScorePdf = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const providers = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
