/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  //register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
  register('firebase-messaging-sw.js', {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
      // self.onnotificationclick = (event) => {
      //   console.log('On notification click: ', event.notification.tag);
      //   event.notification.close();

      //   event.waitUntil(clients.matchAll({
      //     type: "window"
      //   }).then((clientList) => {
      //     for (const client of clientList) {
      //       if (client.url === '/' && 'focus' in client)
      //         return client.focus();
      //     }
      //     if (clients.openWindow)
      //       return clients.openWindow('/');
      //   }));
      // }

    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
