import Repository from '../services/repository.services'
const PREFIX = 'employee/';

const state = {
    employee: [],
    employee_active: [],
    employe_pdf: {},
    employee_item: {},
    employee_doc: [],
    employee_salary: [],
    employee_logs: [],
    vacationList: [],
    success: false
}

const getters = {
    getEmployee: (state) => state.employee,
    getEmployeeItem: (state) => state.employee_item,
    getEmployeeActivo: (state) => state.employee_active,
    getPDF: (state) => state.employe_pdf,
    getEmployeeDocuments: (state) => state.employee_doc,
    getEmployeeSalary: (state) => state.employee_salary,
    getEmployeeLog: (state) => state.employee_logs,
    getVacationsList: (state) => state.vacationList
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getVacations({ commit }, uuid) {
        return Repository.get(`${PREFIX}vacations/${uuid}`).then(
            response => {
                commit('SET_VACATIONS', response.data.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    postItem({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item, `${PREFIX}${datos.entity}`).then(

            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        let item = data.item;
        return Repository.delete(item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpload({ commit }, datos) {
        let item = datos.item;
        return Repository.upload(item, `${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_VACATIONS(state, res){
        console.log("RESPUESTA DE VACACIONES")
        state.vacationList = res;
    },
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "employee":
                    state.employee = res.response.data;
                    break;
                case "employee_active":
                    state.employee_active = res.response.data;
                    break;
                case "employee_doc":
                    state.employee_doc = res.response.data;
                    break;
                case "employee_salary":
                    state.employee_salary = res.response.data;
                    break;
                case "employee_logs":
                    state.employee_logs = res.response.data;
                    break;
            }
        }
    },
    SET_ITEM(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "employee_item":
                    state.employee_item = res.response.data;
                    break;
                case "employe_pdf":
                    state.employe_pdf = res.response.data;
                    break;

            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const employee = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
