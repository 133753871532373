import Repository from '../services/repository.services'
const PREFIX = 'position/';

const state = {
    position: [],
    success: false
}

const getters = {
    getPosition: (state) => state.position,
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "position":
                    state.position = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const position = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
