import PublicService from '../services/public.services'

const state = {
    certificate: {},
    endCertificate: {},
    success: false
}

const getters = {
    getCertificate: (state) => state.certificate,
    getEndCertificate: (state) => state.endCertificate,
}

const actions = {
    get_data({ commit }, entity) {
        return PublicService.get_entity(entity).then(
            response => {
                commit('SET_ENTITY', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                console.log(error)
                commit('SET_ENTITY', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_file({ commit }, entity) {
        return PublicService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
    get_pdf({ commit }, entity) {
        return PublicService.get_entity(entity).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.data);
            },
            error => {
                console.log(error)
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    },
}

const mutations = {
    SET_ENTITY(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "reporte_certificado":
                    console.log('res.response.data',res.response.data)
                    state.certificate = res.response.data;
                    break;
                case "certificado_inspeccion":
                    console.log('res.response.data',res.response.data)
                    state.endCertificate = res.response.data;
                    break;
                // case "certificado_inspeccion_manufactura":
                //     console.log('res.response.data manufactura',res.response.data)
                //     state.certificate = res.response.data;
                //     break;
                    
            }

        }
    },
    SET_SUCCESS(state, success) {
        state.success = success;
    }
}

export const data_public = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
