<template>
  <div fluid class="justify-center login-container">
    <div class="full-center d-flex align-center modules-container">
      <v-row dense align="center" justify="center" class="">
        <v-col cols="12">
          <div class="text-center">
            <span class="slogan white--text"> Time is Gold </span>
            <span class="slogan primary--text">.</span>
          </div>
        </v-col>

        <v-col cols="12" md="12" class="text-center">
          <v-row align="center" justify="center" class="">
            <v-col
              cols="2"
              md="2"
              align-self="center"
              v-for="n in modules"
              :key="n.id"
            >
              <v-btn
                block
                outlined
                color="white"
                @click="goToModule(n.module_view)"
              >
                {{ n.name }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    modules: [],
  }),

  methods: {
    set_url_images(src) {
      return `${process.env.VUE_APP_STORAGE_HOST}${process.env.VUE_APP_STORAGE_MODULES}${src}`;
    },

    goToModule(module) {
      // console.log(module);
      this.$router.push(module);
    },
    set_modules() {
      this.modules = this.$getModules();
    },
  },
  created() {
    this.set_modules();
  },
};
</script>

<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
a,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}
.slogan {
  font-family: "Poppins" !important;
  font-size: 50px;
  font-weight: 700 !important;
  color: #fff;
}
.module-midgrab {
  cursor: pointer;
}
.modules-container {
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: #000;
  background-image: url("../assets/images/background2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}

.login-container {
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: #000;
}

.v-card.on-hover.theme--dark > .v-card__text {
  color: #000;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  width: 100%;
}
.full-center {
  height: 100%;
}
</style>
