import store from '../store/store';
import router from "@/router/index";
const tools = {
    get_location: ()=>{
        // if (!navigator.geolocation) {
        //     store.dispatch("toasts/notify_error_location", "No se pudo obtener su ubicación");
        //     router.replace("/login");
        //     return null
        // }

        // const onSetLocation = position => {
        //     const coord = position.coords;
        //     let location = {
        //         latitud: coord.latitude,
        //         longitud: coord.longitude
        //     }
        //     let data = {
        //         entity: "location",
        //         item: location,
        //     };
        //     store.dispatch("system/post", data)
        // }

        // const onError = e=>{
        //     store.dispatch("toasts/notify_error_location", e);
        //     router.replace("/login");
        // }

        // let config = {
        //     maximumAge: 0, 
        //     enableHighAccuracy: true, 
        //     timeout: 3000 
        // }
        // navigator.geolocation.getCurrentPosition(onSetLocation, onError, config);

    }
}

export default { tools }