import Vue from 'vue'
import validate from '../tools/validate.access'


export default function setup() {
    const user_data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    const list_permission = user_data && user_data.data.authorization? user_data.data.authorization : null;
    
    Vue.prototype.$validatePermission = function (actionName, status = null, module) {
        let item = list_permission.find(element => element.action == actionName && element.module == module);
        if(module === "ADMIN" && validate.credentials.isAdmin()){
            return true;
        }
        else if(item && item.status){
            let statusList = item.status.split(',');
            return statusList.includes(status.name);
        }
        else{
            return false;
        }
        

            //return item ? true : false;
    }

    Vue.prototype.$getPermission= function (module){
        return list_permission.filter((m) => m.module == module);
    }
}