import axios from 'axios';
import store from '@/store/store';
const API_URL = process.env.VUE_APP_SERVICES_CLOUD_HOST;
const API_MESSAGES = process.env.VUE_APP_SERVICES_HOST;
const PREFIX_MSG = 'system/';
const notify_axiosInstance = axios.create();
notify_axiosInstance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('midgrab.app.stk'));
    store.dispatch("configurations/open_loading");

    if (token && token.acstapp) {
        let cia = token.data && token.data.cia_uuid ? token.data.cia_uuid : "host";
        config.headers.Authorization = `Bearer ${token.acstapp}`;
        config.headers.codeuuid = token.data && token.data.uuid ? token.data.uuid : "anonymous";
        config.headers.Requesterby = token.data && token.data.name ? `${token.data.name},${token.data.email},${token.data.rol_code},${cia}` : "anonymous";
      }
      else {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1000);
      }
      return config
}, function (err) {
    return Promise.reject(err);
});
notify_axiosInstance.interceptors.response.use(
    response => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.resolve(response);
    },
    error => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.reject(error.response);
    });
    
class MessagningService {
    get_messageUI(entity) {
        return notify_axiosInstance.get(API_MESSAGES + PREFIX_MSG +  entity);
    }
    post_service(body, entity) {
        return notify_axiosInstance.post(API_URL + entity, body);
    }

    post(data, entity) {
        return axios.post(API_MESSAGES + PREFIX_MSG +  entity, data);
    }

    get_my_message(entity) {
        return axios.get(API_MESSAGES + PREFIX_MSG +  entity);
    }

    update_entity(payload, entity){
        return axios.put(API_MESSAGES + PREFIX_MSG + entity, payload);
    }

}

export default new MessagningService();