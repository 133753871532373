import axios from 'axios';
import store from '../store'

const API_URL = process.env.VUE_APP_SERVICES_HOST;
const PREFIX = 'reports/';

const unintercepted_axiosInstance = axios.create();


unintercepted_axiosInstance.interceptors.request.use(function (config) {

    store.dispatch("configurations/open_loading");

    return config

}, function (err) {
    return Promise.reject(err);
});

unintercepted_axiosInstance.interceptors.response.use(
    response => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.resolve(response);
    },
    error => {
        setTimeout(() => store.dispatch('configurations/close_loading'), 1200);
        return Promise.reject(error.response);
    });

class PublicService {
    get_entity(entity) {        
        return unintercepted_axiosInstance.get(API_URL + PREFIX +  entity);
    }
}

export default new PublicService();