import axios from 'axios';

const API_URL = process.env.VUE_APP_SERVICES_HOST;
const PREFIX_REPORT = 'sales/';


class SalesService{
    get_entity(entity) {        
        return axios.get(API_URL + PREFIX_REPORT +  entity);
    }  

    query_filter(payload, entity){
        return axios.post(API_URL + PREFIX_REPORT + entity, payload);
    }

    create_entity(payload, entity){
        // let dUser = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        // payload.create_by = dUser.data.uuid;
        return axios.post(API_URL + PREFIX_REPORT + entity, payload);
    }

    update_entity(payload, entity){
        return axios.put(API_URL + PREFIX_REPORT + entity, payload);
    }

    delete_entity(payload, entity){
        return axios.delete(API_URL + PREFIX_REPORT + entity,  { data: payload } );
    }

    upload_file(payload, entity){
        const config = { headers: { "Content-Type": "multipart/form-data" } };        
        return axios.post(API_URL + PREFIX_REPORT + entity, payload, config);
    }


}

export default new SalesService();