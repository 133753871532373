import Repository from '../services/repository.services'
const PREFIX = 'fabrication/';
const state = {
    consecutive: 0,
    products: [],
    productionOrders: [],
    productionOrder: {},
    certificates: [],
    StandardReferences: [],
    certificate: {},
    dataCertificate: {},
    success: false,
    item_catalogo: [],
    item_diametro: [],
    sheet: [],
    itemSheet: {},
    groupProduct: [],

    tblDnv: {
        sub_assmebly: 'MCKINNON CHAIN',
        shackle_1: '1" VAN BEEST G-4163',
        shackle_2: '1" VAN BEEST G-4163',
        shackle_3: '1" VAN BEEST G-4163',
        shackle_4: '1" VAN BEEST G-4163',
        master_link: 'N/A',
        wire_rope: '1" 6X36 IWRC, EIPS, GALV',
        thimbles: '1" VAN BEEST G-6142',
        ferrules: 'TALURIT GERRO TKH TYPE',
        dnv_glta_assmebly: 'TAS0000337',
        dnv_glta_shackle_1: 'TAS000011V',
        dnv_glta_shackle_2: 'TAS000011V',
        dnv_glta_shackle_3: 'TAS000011V',
        dnv_glta_shackle_4: 'TAS000011V',
        dnv_glta_main: 'N/A',
        mbl_wire_rope: '460 KN',
        dnv_glta_thimbles: 'N/A',
        size: 'TKH28',
        pic_main: '21G',
        pic_bow_shackle_1: 'BF',
        pic_bow_shackle_2: 'BF',
        pic_bow_shackle_3: 'BF',
        pic_bow_shackle_4: 'BF',
        pic_ml: 'N/A',
        reel: 'BWR/780 3/03/001 TO 004',
        especification: 'US FEDERAL SPECIFICATION FF-T-276b-TYPE III HO DIPPED/EN13411-1',
        batch: 'P0302',
        pic_sub: 'N/A',
        pic_pin_shackle_1: 'BX',
        pic_pin_shackle_2: 'BX',
        pic_pin_shackle_3: 'BX',
        pic_pin_shackle_4: 'BX',
        test_id: '167',
        standart_reference_tbl: 'STANDAR REFERENCE EN1311-3',
        forerunner: 'N/A',
        dnv_ratio: '10,000 KG - 30 DEGREES',
        design_air_temperature: '-20 DEGREES',
        wll_legs: '4 LEGS',
        wll_kgs: '4,500 KG',
        wll_degrees: '60 DEGREES'
    },
    tblDnvRepair: {
        sub_assmebly: 'N/A',
        shackle_1: 'N/A',
        shackle_2: 'N/A',
        shackle_3: 'N/A',
        shackle_4: 'N/A',
        master_link: 'N/A',
        wire_rope: '1" 6X36 IWRC, EIPS, GALV',
        thimbles: '1" VAN BEEST G-6142',
        ferrules: 'TALURIT GERRO TKH TYPE',
        dnv_glta_assmebly: 'N/A',
        dnv_glta_shackle_1: 'N/A',
        dnv_glta_shackle_2: 'N/A',
        dnv_glta_shackle_3: 'N/A',
        dnv_glta_shackle_4: 'N/A',
        dnv_glta_main: 'N/A',
        mbl_wire_rope: '460 KN',
        dnv_glta_thimbles: 'N/A',
        size: 'TKH28',
        pic_main: 'N/A',
        pic_bow_shackle_1: 'N/A',
        pic_bow_shackle_2: 'N/A',
        pic_bow_shackle_3: 'N/A',
        pic_bow_shackle_4: 'N/A',
        pic_ml: 'N/A',
        reel: 'BWR/780 3/03/001 TO 004',
        especification: 'US FEDERAL SPECIFICATION FF-T-276b-TYPE III HO DIPPED/EN13411-1',
        batch: 'P0302',
        pic_sub: 'N/A',
        pic_pin_shackle_1: 'N/A',
        pic_pin_shackle_2: 'N/A',
        pic_pin_shackle_3: 'N/A',
        pic_pin_shackle_4: 'N/A',
        test_id: '167',
        standart_reference_tbl: 'STANDAR REFERENCE EN1311-3',
        forerunner: 'N/A',
        dnv_ratio: '10,000 KG - 30 DEGREES',
        design_air_temperature: '-20 DEGREES',
        wll_legs: '4 LEGS',
        wll_kgs: '4,500 KG',
        wll_degrees: '60 DEGREES'
    },
    tblAsme: {
        legs: 4,
        capacity: "4,500 KG",
        degrees: 60
    },
    indications: [
        { description: "REDUCIR EL ÁNGULO DE TRABAJO TAMBIÉN REDUCIRÁ LA CARGA LÍMITE DE TRABAJO EFECTIVA" },
        { description: "EL ÁNGULO SE MIDE CON RESPECTO AL HORIZONTE ENTRE EL RAMAL Y LA CARGA." },
        { description: "PARA MAYOR INFORMACIÓN O SI REQUIERE CAPACITACIÓN POR FAVOR CONSULTE NUESTRA PÁGINA." }
    ],
    dataACG: {
        acg_col1: "WLL 9.5 T",
        acg_col2: "PL 19 T",
        acg_col3: "BL 57 T",
        indication_acg: "EN 12079-2 Contenedores en alta mar y equipos de izaje asociados - Parte 2: Equipos de izaje - Diseño, fabricación y marcado EN 13889 Grilletes de acero forjado para propósitos generales de izaje - Grilletes en D y grilletes en proa -  Grado 6 - Seguridad IMO/MSC Circular 860 Especificación federal Cadenas y accesorios, acero al carbono y aleado, RR-C-271G DNVGL-ST-E273 Estándar 2.7-3 Unidades portátiles en alta mar (2016)"
    }
}

const getters = {
    getProducts: (state) => state.products,
    getOrders: (state) => state.productionOrders,
    getOrder: (state) => state.productionOrder,
    getCertificate: (state) => state.certificate,
    getDataFakes: (state) => state.dataCertificate,
    getConsecutive: (state) => state.consecutive,
    getStandardRef: (state) => state.StandardReferences,
    getItemCatalogo: (state) => state.item_catalogo,
    getItemDiametro: (state) => state.item_diametro,
    getSheet: (state) => state.sheet,
    getItemSheet: (state) => state.itemSheet,
    getCertificates: (state) => state.certificates,
    getCertificatesSheet: (state) => state.certificatesSheet,
    getGroupProduct: (state) => state.groupProduct
}

const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    post({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    post_callback({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve({ status: response.status, data: response.data.data });
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data) {
        return Repository.delete(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, data) {
        return Repository.update(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    upload({ commit }, data) {
        return Repository.upload(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    get_pdf({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response.data);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getFakeData({ commit }, p) {
        commit('SET_DATA_FAKE', p);
        return new Promise(function (resolve, reject) {
            setTimeout(() => resolve({ status: 200 }), 1000);
        })
    },
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, datos:response.data.data  });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "producto":
                    state.products = res.response;
                    break;
                case "norma_referencia":
                    state.StandardReferences = res.response;
                    break;
                case "orden_fabricacion":
                    state.productionOrders = res.response;
                    break;
                case "reporte_certificado":
                    state.certificates = res.response;
                    break;
                case "datos_certificado":
                    state.dataCertificates = res.response;
                    break;
                case "hconfiguracion_cat":
                    state.item_catalogo = res.response;
                    break;
              
                case "hconfiguracion_order":
                    state.sheet = res.response;
                    break;
                case "reporte_certificado_hconfig":
                    state.certificatesSheet = res.response;
                    break;
                case "hconfiguracion_type_material":
                    state.sheet = res.response;
                    break;
                case "grupo_producto":
                    state.groupProduct = res.response;
                    break;
            }
        }
    },
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "orden_fabricacion_filter":
                    state.productionOrders = res.datos;
                    break;
                case "cat_diamaters":
                    state.item_diametro = res.response;
                    break;
            }
        }
    },
    SET_ITEM(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "orden_fabricacion":
                    state.productionOrder = res.response.data;
                    break;
                case "reporte_certificado":
                    state.certificate = res.response.data;
                    break;
                case "datos_certificado":
                    state.dataCertificate = res.response.data;
                    break;
                case "numero_consecutivo":
                    state.consecutive = res.response.data;
                    break;
                case "hconfiguracion":
                    state.itemSheet = res.response;
                    break;
            }
        }
    },
    SET_DATA_FAKE(state, p) {

        let dataC = {
            stamp: false,
            numbers_to_create: 1,
            certificate_type: '-',
            certificate_date: '-',
            order_production_uuid: '-',
            description: '-',
            sling_id: '-',
            client_id: '-',
            part_number: '-',
            standard_ref: '-',
            inspection: 'STANDARD 2022',
            model: '-',
            product: {},
            product_type: null,
            product_legs_data: {},
            product_degrees: {},
            manufacture_date: '-',
            prefix: '-',
            certificate_number: '-',
            days_validity: 0, //365,
            description: '1" X 4.28 M,DNVGL-ST-E2.7-1 TYPE APPROVED 4-LEG GALVANIZED WIRE ROPE SLING, EIPS, IWRC 6X36 WS ON 1"; MCKINNON CHAIN  HA-32QA  8 OS+ OBLONG SUB- ASSEMBLY, WLL 19. TON & SHACKLES (4) 1", WLL 8.5 TON, IDENTIFIED WITH PADLOCK NUMBER  A5921 TO  A5924,  VAN BEEST G-4163; GALVANIZED  HEAVY-DUTY THIMBLES VAN BEEST G-6142 ACCORDING TO US FEDERAL SPECIFICATION  FF-T-276b TYPE III-      EN 13411-1.RATING 10,000 KG.DEVICE: FTCB6008. ',
            reference_norm_data: {}, //{ uuid: "G2JULRR-TX9", name: "DNV GL 2.7-1", clave: "DNV" },
            header_col1: 'VERTICAL',
            header_col2: 'ENLAZADO',
            header_col3: 'CANASTA',
        }
        let data = {};
        switch (p.nomaRef.clave) {
            case "DNV":
                if (p.isRepair) {
                    dataC.certificate_tbl = state.tblDnvRepair
                }
                else {
                    dataC.certificate_tbl = state.tblDnv
                }
                dataC.inspection = "STANDARD 2021";
                data = Object.assign({}, dataC);
                break;
            case "ASME":
                dataC.table_asme = state.tblAsme
                dataC.indications = state.indications;
                data = Object.assign({}, dataC);
                break;
            case "EN":
                dataC.table_asme = state.tblAsme
                data = Object.assign({}, dataC, state.dataACG);
                break;
            default:
                dataC.table_asme = state.tblAsme
                dataC.indications = state.indications;
                data = Object.assign({}, dataC);
                break;
        }
        state.dataCertificate = data;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    }
}

export const production = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
