import Repository from '../services/repository.services'
const PREFIX = 'shopping/';

const state = {
    arrayStore: [],
    providersType: [],
    shopping: [],
    shoppingItem:{},
    shoppingProduct: [],
    orderPayment:[],
    docShopping:[],
    orderFilter: [],
    requisitionStore:[],
    requisitionProducts: [],
    requisition: [],
    requisitionItem:{},
    requisitionConfirmado: [],
    requisitionLogs: [],
    requisitionComment: [],
    requisitionPDF:{},
    requisitionSearch: [],
    requisitionDoc: [],
    requisitionAttended: [],
    success: false
}

const getters = {
    getShopping: (state) => state.shopping,
    getShoppingSearch: (state) => state.shopping,
    getItemShopping: (state) => state.shoppingItem,
    getStore: (state) => state.arrayStore,
    getTypeProviders: (state) => state.providersType,
    getProductOrders: (state) => state.shoppingProduct,
    getOrderPayment: (state) => state.orderPayment,
    getDocOrderPayment: (state) => state.docShopping,
    getShoppingFilter: (state) => state.orderFilter,
    getRequisitionStore: (state) => state.requisitionStore,
    getRequisitionProduct: (state) => state.requisitionProducts,
    getRequisition: (state) => state.requisition,
    getRequisitionItem: (state) => state.requisitionItem,
    getRequisitionLog: (state) => state.requisitionLogs,
    getRequisitionComment: (state) => state.requisitionComment,
    getRequisitionPDF: (state) => state.requisitionPDF,
    getRequisitionSearch: (state) => state.requisitionSearch,
    getRequisitionDoc: (state) => state.requisitionDoc,
    getRequisitionAttended: (state) => state.requisitionAttended
}
const actions = {
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    postItem({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getItem({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_ITEM', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_ITEM', {});
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, response:response.data});
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpload({ commit }, datos) {
        let item = datos.item;
        return Repository.upload(item,`${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpdate({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        let item = data.item;
        return Repository.delete(item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "order":
                    state.shopping = res.response.data;
                    break;
                case "providers_type":
                    state.providersType = res.response.data;
                    break;
                case "store_request":
                    state.arrayStore = res.response.data;
                    break;
                case "order_products":
                    state.shoppingProduct = res.response.data;
                    break;
                case "order_payment":
                    state.orderPayment = res.response.data;
                    break;
                case "requisition_store":
                    state.requisitionStore = res.response.data;
                    break;
                case "requisition_products":
                    state.requisitionProducts = res.response.data;
                    break;
                case "requisition_logs":
                    state.requisitionLogs = res.response.data;
                    break; 
                case "requisition_comment":
                    state.requisitionComment = res.response.data;
                    break;
                case "requisition_doc":
                    state.requisitionDoc = res.response.data;
                    break;
                case "requisition_attended":
                    state.requisitionAttended = res.response.data;
                    break; 
            }
        }
    },
    SET_ITEM(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "order":
                    state.shoppingItem = res.response.data;
                    break;
                case "order_document":
                    state.docShopping = res.response.data;
                    break;
                case "requisition_edit":
                    state.requisitionItem = res.response.data;
                    break;
                case "requisition_pdf_admin":
                    state.requisitionPDF = res.response.data;
                    break;
                case "requisition_pdf":
                    state.requisitionPDF = res.response.data;
                    break;
            }
        }
    },
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "order_filter":
                    state.shopping = res.response.data;
                    break;
                case "requisition_search":
                    state.requisitionStore = res.response.data;
                    break;
                case "requisition_store_admin":
                    state.requisitionStore = res.response.data;
                    break;
                case "requisition_products_admin":
                    state.requisitionProducts = res.response.data;
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const shopping = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
